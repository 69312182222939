import React from 'react';

import DownloadButton from "./DownloadButton";
interface Props {
    name: string;
    description: string;
    image?: string;
    year: number;
    downloads: [Downloads];
}

interface Downloads {
    name: string;
    url: string;
    date: Date;
    sizeInBytes: number;
    Icon?: React.FC;
}

const GameCard = ({name, year, image, description, downloads}:Props) => {
    return (
        <div className={"bg-light-blue p-4 lg:p-5 rounded shadow space-y-5"}>
            <div className={"flex flex-col lg:flex-row items-center lg:space-x-5 h-full"}>
                {image && <img className={'h-24 w-24 rounded-full object-cover'} src={`/images/${image}`} alt='Game image'/>}
                <div className={'flex flex-col justify-between space-y-2 h-full'}>
                    <h2 className={"text-light-accent text-xl"}>{name} (<span>{year}</span>)</h2>
                    { description &&
                        <p className={"text-light-accent pb-2 w-full"}>
                            {description}
                        </p>
                    }
                    {downloads && downloads.length > 0 && downloads.map(download => <DownloadButton key={download.name} Icon={download.Icon} url={download.url} name={download.name} sizeInBytes={download.sizeInBytes} date={download.date}/>)}
                </div>
            </div>
        </div>
    );
};

export default GameCard;