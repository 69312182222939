import React from 'react';
import {Download} from "phosphor-react";
import {filesize} from "filesize";
import dayjs from "dayjs";

interface Props {
    name: string;
    url: string;
    date: Date;
    sizeInBytes: number;
    Icon?: any;
}

const DownloadButton = ({name, date, sizeInBytes, Icon=Download, url}: Props) => {
    return (
        <div className={"flex flex-col lg:flex-row"}>
            <a href={`${url}`} download={name} className={"btn bg-yellow-accent text-light-accent rounded px-4 py-2 inline-flex items-center"}>
                <Icon size={26}/>
                <span className={"ml-2"}>{name}</span>
            </a>
            <div className={"space-x-2"}>
                <span className={"text-sm italic lg:ml-4 text-light-accent"}>
                    {filesize(sizeInBytes, {exponent: 2, base: 2, standard: "jedec"}).toString()}
                </span>
                    <span className={"text-sm italic lg:ml-2 text-light-accent"}>
                    {dayjs(date).format('DD/MM/YYYY')}
                </span>
            </div>
        </div>
    );
};

export default DownloadButton;