import React from 'react';
import GameCard from "./GameCard";
import {WindowsLogo} from "phosphor-react";

const Games = () => {
    return (
        <div className={"mt-5 grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-8"}>
            <GameCard
                name={'Anima'}
                year={2020}
                image={'anima.png'}
                downloads={[
                    {
                        name: 'Anima-final-windows.zip',
                        url: 'https://animagame.s3.eu-central-1.amazonaws.com/Anima-final-windows.zip',
                        date: new Date("10/6/2023"),
                        sizeInBytes: 420819822,
                        Icon: WindowsLogo,
                    }
                ]}
                description={'The best game involving a bird'}
            />
            <GameCard
                name={'Momentag'}
                year={2023}
                image={'momentag.png'}
                downloads={[
                    {
                        name: 'Momentag6-28.zip',
                        url: 'https://animagame.s3.eu-central-1.amazonaws.com/Momentag6-28.zip',
                        date: new Date("10/6/2023"),
                        sizeInBytes: 45800000,
                        Icon: WindowsLogo,
                    }
                ]}
                description={'In Momentag your goal is to grapple and dash around to reach high speeds while competing against other players in various competitions such as keepaway and infection tag.'}
            />

            <GameCard
                name={'Safe Haven'}
                year={2023}
                image={'safehaven.png'}
                downloads={[
                    {
                        name: 'SafeHavenDemo.zip',
                        url: 'https://animagame.s3.eu-central-1.amazonaws.com/SafeHavenDemo.zip',
                        date: new Date("10/6/2023"),
                        sizeInBytes: 243000000,
                        Icon: WindowsLogo,
                    }
                ]}
                description={'Safe Haven is a stealth-focused interactive story that follows the journey of two young siblings, Nova and Samoya, as they try to find their way to safety through a city that is ridden with war. You can find the controls in the settings menu. This demo was made in 7 weeks as a part of a minor. Have fun playing!'}
            />

        </div>
    );
};

export default Games;