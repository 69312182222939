import React from 'react';
import './App.css';
import Games from "./components/Games";
const greetings = [
    'hola',
    'hej',
    'hallo',
    'hello',
    'guten tag',
    'olá',
    'bonjour',
]
function App() {
    const greeting = greetings[Math.floor(Math.random()*greetings.length)];
  return (
    <div className="bg-dark-blue min-h-screen py-5 px-4">
        <div className={'bg-light-blue inline-flex flex-col p-5 rounded w-full lg:w-1/2 mb-4'}>
            <h1 className={"capitalize text-light-accent text-2xl mb-3 inline font-bold"}>{greeting}!</h1>
            <p className={"text-light-accent"}>
                When vast amounts of human people kept asking for the download links of all the amazing HAN games an idea was born; why not collect all the download links in a public webpage. Well fear not, this is that webpage!
            </p>
            <p className={"text-light-accent mt-2"}>
                Would you like your game to be added or edited? Hit me up either via the 'Game, life after the minor' Discord server (my name is Ellard) or shoot me an email over at <strong>ellard@edk.media</strong>.
            </p>

            <p className={"text-light-accent mt-2 italic font-bold text-orange-300 text-sm"}>
                Notice: This is a community run website, download and play at your own risk!
            </p>
        </div>
        <Games/>
    </div>
  );
}

export default App;
